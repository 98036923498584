import React from 'react'
import { Link } from 'gatsby'
import { RichText, Date } from 'prismic-reactjs'
import styled from '@emotion/styled'
import css from '@emotion/css'
import get from 'lodash/get'
import { mq } from '../../utils/responsive'
import { Colors } from '../../utils/colors'
import moment from 'moment'
import { darken, lighten } from 'polished'
import truncate from 'truncate'
import { rhythm } from '../../utils/typography'
import dateFormat from '../../utils/date-format'
import { NonStretchedImage } from './image'

const NewsCardContainer = styled(Link)`
    display: grid;
    grid-template-columns: 7fr 6fr;
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
    margin-bottom: 4em;
    transition: all 150ms ease-in-out;
    text-decoration: none;

    ${mq.large(css`
      grid-template-columns: 4.5fr 7fr;
    `)}

    ${mq.medium(css`
      grid-template-columns: 1fr;
    `)}

    ${mq.small(css`
      margin-bottom: 2em;
    `)}

    &:hover {
        box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
        transition: all 150ms ease-in-out;

        .NewsCardAction {
            transition: all 150ms ease-in-out;

            span {
                transform: translateX(0px);
                opacity: 1;
                transition: transform 150ms ease-in-out;
            }
        }

        .NewsCardContent::before {
            opacity: 0.02;
            transition: all 150ms ease-in-out;
        }

        .NewsCardImageContainer::before {
            opacity: 0.2;
            transition: all 150ms ease-in-out;
        }
    }
`

const NewsCardContent = styled('div')`
  background: white;
  padding: 4em 3em 2.25em 3em;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: ${Colors.linkColor};
    mix-blend-mode: multiply;
    opacity: 0;
    transition: all 150ms ease-in-out;
  }

  ${mq.large(css`
    padding: 3.25em 2.5em 2em 2.5em;
  `)}

  ${mq.medium(css`
    grid-row: 2;
  `)}
`

const NewsCardCategory = styled('h6')`
  font-weight: 600;
  color: gray;
`

const NewsCardTitle = styled('h3')`
  margin-bottom: 0.5em;
  margin-top: 0.5em;
`

const NewsCardBlurb = styled('div')`
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 5em;
  color: ${Colors.bodyColor};

  ${mq.medium(css`
    margin-bottom: 2.5em;
  `)}
`

const NewsCardAction = styled('div')`
  font-weight: 600;
  text-decoration: none;
  transition: all 150ms ease-in-out;

  span {
    margin-left: 1em;
    transform: translateX(-8px);
    display: inline-block;
    transition: transform 400ms ease-in-out;
  }
`

const NewsCardImageContainer = styled('div')`
  background: ${lighten(0.2, Colors.rose)};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 2em;

  ${mq.medium(css`
    // padding-top: 3em;
    // max-height: 200px;
    padding: ${rhythm(1)};
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `)}

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: ${darken(0.4, Colors.rose)};
    mix-blend-mode: multiply;
    opacity: 0;
    transition: all 150ms ease-in-out;
  }
`

const NewsImage = styled(NonStretchedImage)`
  width: 100%;
`

const BlogCard = ({
  node: {
    date,
    title,
    content,
    imageSharp,
    _meta: { uid },
  },
  urlPrefix,
  lang,
}) => {
  const imageFluid = get(imageSharp, 'childImageSharp.fluid')
  moment.locale(lang)
  return (
    <NewsCardContainer to={`${urlPrefix}${uid}`}>
      <NewsCardContent className="NewsCardContent">
        <NewsCardCategory>
          {moment(Date(date)).format(dateFormat(lang))}
        </NewsCardCategory>
        <NewsCardTitle>{RichText.asText(title)}</NewsCardTitle>
        <NewsCardBlurb>{truncate(RichText.asText(content), 120)}</NewsCardBlurb>
        <NewsCardAction className="NewsCardAction">
          Lire <span>&#8594;</span>
        </NewsCardAction>
      </NewsCardContent>
      {imageFluid && (
        <NewsCardImageContainer className="NewsCardImageContainer">
          <NewsImage fluid={imageFluid} />
        </NewsCardImageContainer>
      )}
    </NewsCardContainer>
  )
}

export default BlogCard
